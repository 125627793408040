import { Clickable } from "@client/components/Clickables";
import React, { useState } from "react";
import {
    Dialog,
    DialogTrigger,
    Modal,
    ModalOverlay,
} from "react-aria-components";

import { Svg } from "../Svg";
import { ContactUsFormContent } from "./ContactUsFormContent";
import { DialogHooks } from "./DialogHooks";
import { VisualOverlay } from "./VisualOverlay";

import styles from "./index.module.scss";

type Props = React.PropsWithChildren<{
    defaultIsOpen?: boolean;
    defaultIsSubmitted?: boolean;
}>;

export default function ContactUsForm(props: Props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <DialogTrigger
            isOpen={props.defaultIsOpen}
            onOpenChange={(state) => setIsOpen(state)}
        >
            <DialogHooks />
            <VisualOverlay isOpen={isOpen} />
            {props.children}
            <ModalOverlay className={styles.overlay} isDismissable>
                <Modal className={styles.popover}>
                    <Dialog
                        className={styles.dialog}
                        aria-label="Connect with us."
                    >
                        {({ close }) => (
                            <>
                                <Clickable
                                    className={styles.close}
                                    onClick={close}
                                    autoFocus
                                >
                                    <Svg name="close" />
                                </Clickable>
                                <ContactUsFormContent
                                    defaultIsSubmitted={
                                        props.defaultIsSubmitted || false
                                    }
                                />
                            </>
                        )}
                    </Dialog>
                </Modal>
            </ModalOverlay>
        </DialogTrigger>
    );
}
