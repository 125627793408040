import { useGSAP } from "@gsap/react";
import { gsap } from "gsap/dist/gsap";
import React from "react";

import styles from "./scroll-progress-bar.module.scss";

export default function ScrollProgressBar() {
    const inner = React.useRef<HTMLDivElement>(null);
    useGSAP(() => {
        gsap.to(inner.current, {
            width: "100%",
            scrollTrigger: {
                trigger: "html",
                start: "top top",
                end: "max",
                scrub: 0.3,
            },
        });
    });
    return (
        <div className={styles.progress}>
            <div ref={inner} />
        </div>
    );
}
