import TabbedBlock from "@client/components/CaseStudies/TabbedBlock";
import { CaseStudyHero } from "@client/components/CaseStudyHero";
import { ButtonPrimary } from "@client/components/Clickables";
import { FullWidthMedia } from "@client/components/FullWidthMedia";
import { PageSkeleton } from "@client/components/PageSkeleton";
import { SpacerBlock } from "@client/components/SpacerBlock";
import { SplitBlock } from "@client/components/SplitBlock";
import StatsBlock from "@client/components/StatsBlock";
import React from "react";

import {
    MediaChooserBlock as MediaChooserBlockInterface,
    templates,
} from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import QuotesBlock from "../components/Quotes";
import { FeaturedRichTextBlock } from "../components/RichText";
import CaseStudyShowcaseBlock from "../components/ShowcaseBlock";

import styles from "../components/CaseStudies/case-studies.module.scss";

export const Template = (props: templates.CaseStudyTemplate) => {
    const logoFadeInRef = React.useRef<HTMLDivElement>(null);

    const projectLinkValue = props.page.project_link.value[0]
        ? props.page.project_link.value[0].value
        : "";

    const returnMediaURL = (mediaBlock: MediaChooserBlockInterface) => {
        const media = mediaBlock[0];

        switch (media.type) {
            case "image":
                return media.value.url;
            case "video":
                return media.value.poster;
            case "embed":
                return media.value.embed!;
        }
    };

    return (
        <React.StrictMode>
            <PageSkeleton {...props.page} fixedLogoFadeInRef={logoFadeInRef}>
                <CaseStudyHero
                    richtext={
                        props.page.hero_text ? props.page.hero_text.html : ""
                    }
                    client={props.page.client}
                />
                <FullWidthMedia value={props.page.hero_image.value} />
                <section className={styles.projectIntro}>
                    <div className={styles.roleListWrapper}>
                        <h4>Our Role:</h4>
                        <ul className={styles.roleList}>
                            {props.page.roles.value.map((role, j) => {
                                return (
                                    <li key={j}>
                                        {role.value}
                                        {j <
                                            props.page.roles.value.length -
                                                1 && (
                                            <span className={styles.comma}>
                                                ,{" "}
                                            </span>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                        {projectLinkValue && projectLinkValue.url && (
                            <ButtonPrimary
                                href={projectLinkValue.url}
                                icon="arrow"
                                target="_blank"
                            >
                                {projectLinkValue.display_name
                                    ? projectLinkValue.display_name
                                    : "Visit Live Site"}
                            </ButtonPrimary>
                        )}
                    </div>
                    <p>{props.page.intro}</p>
                </section>
                <StreamField
                    tagName={React.Fragment}
                    value={props.page.body.value}
                    components={{
                        rich_text: (block) => (
                            <FeaturedRichTextBlock {...block.value} time={0} />
                        ),
                        showcase: (block) => (
                            <CaseStudyShowcaseBlock {...block.value} />
                        ),
                        split_block: SplitBlock,
                        quotes: (block) => <QuotesBlock {...block.value} />,
                        stats: (block) => {
                            return (
                                <>
                                    <div ref={logoFadeInRef} />
                                    <StatsBlock {...block.value} />
                                </>
                            );
                        },
                        spacer: (block) => <SpacerBlock value={block.value} />,
                        tabs: (block) => <TabbedBlock {...block.value} />,
                    }}
                />

                <section
                    className={styles.nextStudyBlockContainer}
                    style={{
                        "--next-study-hover-bg": `url('${
                            returnMediaURL(
                                props.page.next_case_study.showcase_media.value,
                            ) || ""
                        }')`,
                    }}
                >
                    <div className={styles.nextStudyBlock}>
                        <div>
                            <p>Up Next:</p>
                            <p>{props.page.next_case_study.client}</p>
                            <p>{props.page.next_case_study.showcase_title}</p>
                        </div>
                        <span>
                            <ButtonPrimary
                                href={props.page.next_case_study.url}
                                icon="arrow"
                            >
                                View Case Study
                            </ButtonPrimary>
                        </span>
                    </div>
                </section>
            </PageSkeleton>
        </React.StrictMode>
    );
};
