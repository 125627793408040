/* eslint-disable */
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap/dist/gsap";
import React from "react";

import styles from "./animated-hero-logo.module.scss";

export default function AnimatedHeroLogo() {
    const wrapper = React.useRef<HTMLDivElement>(null);

    useGSAP(() => {
        // All Animation Here ---->
        const tl = gsap.timeline();
        const q = gsap.utils.selector(wrapper.current);

        // prettier-ignore
        tl
            .timeScale(1.5) // tweak to speed up entire animation
            // .timeScale(.5) // <-- Turn Off! For testing only

            // red = #ea521a | green = #289581 | blue = #0587c1

            // .set("#circle-2, #circle-5, #circle-8", 	{display: "none"},)
            .set(q('.center-mask'), {alpha: 0})

            // start with logo. logo circles fade off leaving three circles (2R, 5G, 8B)
            .to(q(".logo-circle"), 			{delay: 1.0, duration: 0.5, alpha: 0, ease: "none"}, "first")
            .to(q(".red-circle"), 				{delay: 1.0, duration: 0.5, fill: "var(--red)", ease: "none"}, "first")
            .to(q(".green-circle"), 			{delay: 1.0, duration: 0.5, fill: "var(--teal)", ease: "none"}, "first")
            .to(q(".blue-circle"), 			{delay: 1.0, duration: 0.5, fill: "var(--blue)", ease: "none"}, "first");

        // three circles spin in a circle and turn into one black circle
        let a1 = (3 * Math.PI) / 6, // bottom
            a2 = (7 * Math.PI) / 6, // top left
            a3 = (11 * Math.PI) / 6, // top right
            r = 60,
            delay = 0,
            duration = 1.25;

        // prettier-ignore
        tl
            .to(q(".red-circle"), 				{delay, duration, ease: "power3.inOut",
                cx: Math.cos(a1) * r,
                cy: Math.sin(a1) * r }, "second")
            .to(q(".green-circle"), 			{delay, duration, ease: "power3.inOut",
                cx: Math.cos(a2) * r,
                cy: Math.sin(a2) * r  }, "second")
            .to(q(".blue-circle"), 			{delay, duration, ease: "power3.inOut",
                cx: Math.cos(a3) * r,
                cy: Math.sin(a3) * r  }, "second")

        r = 45;
        delay = 0.9;
        duration = 1.15;
        // prettier-ignore
        tl
            .to(q(".center-mask"), {delay, duration: 0, ease: "none", alpha: 1}, "second")
            .to(q(".red-circle"), 				{delay, duration, ease: "power3.inOut",
                cx: Math.cos(a2) * r,
                cy: Math.sin(a2) * r }, "second")
            .to(q(".green-circle"), 			{delay, duration, ease: "power3.inOut",
                cx: Math.cos(a3) * r,
                cy: Math.sin(a3) * r }, "second")
            .to(q(".blue-circle"), 			{delay, duration, ease: "power3.inOut",
                cx: Math.cos(a1) * r,
                cy: Math.sin(a1) * r }, "second")

        // prettier-ignore
        tl
				.to(q(".color-circle"), 		{delay: 1.8, duration: 0.95, cx: 0, cy: 0, ease: "power3.inOut"}, "second")

				// black circle scales and turns green
				.fromTo("#dot-green",			{scale: 0.25, alpha: 0, svgOrigin: '0 0'},{delay: 0.25, duration: 0.35, alpha: 1, scale: 1.0, ease: "back.out(1.0)"}, "third")

				// green circle morphs into blue burst
				.to("#dot-green", 				{delay: 0.25, duration: 0.15 , fill: "var(--blue)", ease: "back.out(1.8)"},"fourth")
				.to("#dot-green", 				{delay: 0.25, duration: 0.35 , morphSVG:"#burst-blue", ease: "back.out(1.8)"},"fourth")

				// blue burst scales and turns red
				.to("#dot-green", 				{delay: 0.25, duration: 0.15 , fill: "var(--red)", ease: "back.out(1.8)"},"fifth")
				.to("#dot-green", 				{delay: 0.25, duration: 0.35 , morphSVG:"#burst-red", ease: "back.out(2.25)"},"fifth")

				.set("#circle-9", 	{transformOrigin: "50% 50%", scale: 0.5},)
				.set("#circle-1", 	{transformOrigin: "50% 50%", y: 40},)
				.set("#circle-2", 	{transformOrigin: "50% 50%", y: 40, x: -40, display: "block", alpha: 0},)
				.set("#circle-3", 	{transformOrigin: "50% 50%", x: -40},)
				.set("#circle-4", 	{transformOrigin: "50% 50%", x: -40, y: -40},)
				.set("#circle-5", 	{transformOrigin: "50% 50%", y: -40, display: "block", alpha: 0},)
				.set("#circle-6", 	{transformOrigin: "50% 50%", x: 40, y: -40},)
				.set("#circle-7", 	{transformOrigin: "50% 50%", x: 40},)
				.set("#circle-8", 	{transformOrigin: "50% 50%", y: 40, x: 40, display: "block", alpha: 0},)


				// red burst morphs back into black logo, should feel like a bouncy explosion
				.to("#dot-green", 				{delay: 0.3, duration: 0.25 , scale: 0.5, alpha: 0, ease: "power3.out"},"sixth")
				.to(q(".logo-circle"), 			{delay: 0.5, duration: 0.25 , alpha: 1, ease: "power3.inOut"},"sixth")
				.to("#circle-9", 				{delay: 0.5, duration: 2.0 , scale: 1.0, ease: "elastic.out(1.3, 0.4)"},"sixth")

				.to("#circle-1", 				{delay: 0.5, duration: 0.4 , y: -20, scaleY: 0.8, ease: "power3.out" },"sixth")
				.to("#circle-1", 				{delay: 1.0, duration: 1.6 , y: 0, scaleY: 1, ease: "elastic.out(1.3, 0.4)"},"sixth")

				.to("#circle-2", 				{delay: 0.5, duration: 0.4 , y: -20, x: 20, scale: 0.8, ease: "power3.out" },"sixth")
				.to("#circle-2", 				{delay: 1.0, duration: 1.6 , y: 0, x: 0, scale: 1, ease: "elastic.out(1.3, 0.4)"},"sixth")

				.to("#circle-3", 				{delay: 0.5, duration: 0.4 , x: 20, scaleX: 0.8, ease: "power3.out" },"sixth")
				.to("#circle-3", 				{delay: 1.0, duration: 1.6 , x: 0, scaleX: 1, ease: "elastic.out(1.3, 0.4)"},"sixth")

				.to("#circle-4", 				{delay: 0.5, duration: 0.4 , y: 20, x: 20, scale: 0.8, ease: "power3.out" },"sixth")
				.to("#circle-4", 				{delay: 1.0, duration: 1.6 , y: 0, x: 0, scale: 1, ease: "elastic.out(1.3, 0.4)"},"sixth")

				.to("#circle-5", 				{delay: 0.5, duration: 0.4 , y: 20, scaleY: 0.8, ease: "power3.out" },"sixth")
				.to("#circle-5", 				{delay: 1.0, duration: 1.6 , y: 0, scaleY: 1, ease: "elastic.out(1.3, 0.4)"},"sixth")

				.to("#circle-6", 				{delay: 0.5, duration: 0.4 , y: 20, x: -20, scale: 0.8, ease: "power3.out" },"sixth")
				.to("#circle-6", 				{delay: 1.0, duration: 1.6 , y: 0, x: 0, scale: 1, ease: "elastic.out(1.3, 0.4)"},"sixth")

				.to("#circle-7", 				{delay: 0.5, duration: 0.4 , x: -20, scaleX: 0.8, ease: "power3.out" },"sixth")
				.to("#circle-7", 				{delay: 1.0, duration: 1.6 , x: 0, scaleX: 1, ease: "elastic.out(1.3, 0.4)"},"sixth")

				.to("#circle-8", 				{delay: 0.5, duration: 0.4 , y: -20, x: -20, scale: 0.8, ease: "power3.out" },"sixth")
				.to("#circle-8", 				{delay: 1.0, duration: 1.6 , y: 0, x: 0, scale: 1, ease: "elastic.out(1.3, 0.4)"},"sixth")

        // and then we repeat
        tl.repeat(-1);

        // const x = GSDevTools.create({ animation: tl });
        return () => {
            tl.kill();
            // x.kill();
        };
    });
    return (
        <div ref={wrapper} className={styles.container}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                version="1.1"
                viewBox="-100 -100 200 200"
            >
                <g id="three-swirling-dots">
                    <clipPath id="blackCenter">
                        <circle
                            className="color-circle red-circle"
                            r="22.5"
                            cx={Math.cos((7 * Math.PI) / 4) * 77.5}
                            cy={Math.sin((7 * Math.PI) / 4) * 77.5}
                            fill="white"
                        />
                        <circle
                            className="color-circle green-circle"
                            r="22.5"
                            cx={Math.cos((2 * Math.PI) / 4) * 77.5}
                            cy={Math.sin((2 * Math.PI) / 4) * 77.5}
                            fill="white"
                        />
                        <circle
                            className="color-circle blue-circle"
                            r="22.5"
                            cx={Math.cos((5 * Math.PI) / 4) * 77.5}
                            cy={Math.sin((5 * Math.PI) / 4) * 77.5}
                            fill="white"
                        />
                    </clipPath>
                    <circle
                        className="color-circle red-circle"
                        r="22.5"
                        cx={Math.cos((7 * Math.PI) / 4) * 77.5}
                        cy={Math.sin((7 * Math.PI) / 4) * 77.5}
                        fill="var(--red)"
                    />
                    <circle
                        className="color-circle green-circle"
                        r="22.5"
                        cx={Math.cos((2 * Math.PI) / 4) * 77.5}
                        cy={Math.sin((2 * Math.PI) / 4) * 77.5}
                        fill="var(--teal)"
                    />
                    <circle
                        className="color-circle blue-circle"
                        r="22.5"
                        cx={Math.cos((5 * Math.PI) / 4) * 77.5}
                        cy={Math.sin((5 * Math.PI) / 4) * 77.5}
                        fill="var(--blue)"
                    />
                    <circle
                        cx="0"
                        cy="0"
                        r="22.5"
                        fill="var(--black)"
                        clipPath="url(#blackCenter)"
                        className="center-mask"
                    />
                </g>
                <g id="logo-black">
                    {Array(8)
                        .fill(null)
                        .map((_, i) => {
                            const h = 77.5;
                            const a = (Math.PI * (i - 2)) / 4;
                            return (
                                <circle
                                    key={i}
                                    id={`circle-${i + 1}`}
                                    className="logo-circle"
                                    cx={Math.cos(a) * h}
                                    cy={Math.sin(a) * h}
                                    r="22.5"
                                />
                            );
                        })}
                    <circle
                        id="circle-9"
                        className="logo-circle"
                        cx={0}
                        cy={0}
                        r="22.5"
                    />
                </g>
                <path
                    id="dot-green"
                    d="M -25 0 a 25 25 0 0 0 50 0 a 25 25 0 0 0 -50 0z"
                    fill="var(--teal)"
                />
                <path
                    id="burst-blue"
                    className={styles.burst}
                    d="M -15 -50 c 0 -8.16 6.54 -14.82 14.7 -15 c 8.4 -0.18 15.3 6.9 15.3 15.36 c 0 4.86 5.88 7.26 9.3 3.84 c 5.94 -5.94 15.84 -6.12 21.66 -0.06 c 5.64 5.88 5.58 15.24 -0.18 21.06 c -3.42 3.42 -1.02 9.3 3.84 9.3 c 8.4 0 15.48 6.9 15.36 15.3 c -0.18 8.16 -6.78 14.7 -15 14.7 c -4.86 0 -7.26 5.88 -3.84 9.3 c 5.94 5.94 6.12 15.84 0.06 21.66 c -5.88 5.64 -15.24 5.58 -21.06 -0.18 c -3.42 -3.42 -9.3 -1.02 -9.3 3.84 c 0 8.4 -6.9 15.48 -15.3 15.36 c -8.16 -0.18 -14.7 -6.78 -14.7 -15 c 0 -4.86 -5.88 -7.26 -9.3 -3.84 c -5.94 5.94 -15.84 6.12 -21.66 0.06 c -5.64 -5.88 -5.58 -15.24 0.18 -21.06 c 3.42 -3.42 1.02 -9.3 -3.84 -9.3 c -8.4 0 -15.48 -6.9 -15.36 -15.3 c 0.18 -8.16 6.78 -14.7 15 -14.7 c 4.86 0 7.26 -5.88 3.84 -9.3 c -5.94 -5.94 -6.12 -15.84 -0.06 -21.66 c 5.88 -5.64 15.24 -5.58 21.06 0.18 c 3.42 3.42 9.3 1.02 9.3 -3.84 z"
                    fill="var(--blue)"
                />
                <path
                    id="burst-red"
                    className={styles.burst}
                    d="M -20 -66.32 a 18 18 90 0 1 40 0 l 0 18 l 12.72 -12.72 a 18 18 90 0 1 28.32 28.32 l -12.72 12.72 l 18 0 a 18 18 90 0 1 0 40 l -18 0 l 12.72 12.72 a 18 18 90 0 1 -28.32 28.32 l -12.72 -12.72 l 0 18 a 18 18 90 0 1 -40 0 l 0 -18 l -12.72 12.72 a 18 18 90 0 1 -28.32 -28.32 l 12.72 -12.72 l -18 0 a 18 18 90 0 1 0 -40 l 18 0 l -12.72 -12.72 a 18 18 90 0 1 28.32 -28.32 l 12.72 12.72 z"
                    fill="var(--red)"
                />
            </svg>
        </div>
    );
}
