import colors from "@client/styles/color-variables.module.scss";
import themes from "@client/styles/theming.module.css";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import React from "react";

import { ServicesStoryBlock } from "@reactivated";

import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { RichTextBlock } from "../RichText";
import { ScrollIndicator } from "../ScrollIndicator";

import styles from "./services.module.scss";

gsap.registerPlugin(ScrollTrigger);

const a = (5 * Math.PI) / 6;
const cos = Math.cos(a);
const sin = Math.sin(a);

export default function StoryBlock({
    value: props,
    globalHeaderRef,
}: {
    value: ServicesStoryBlock;
    globalHeaderRef?: React.RefObject<HTMLDivElement>;
}) {
    const sliderRef = React.useRef<HTMLDivElement>(null);
    useGSAP(() => {
        const slider = sliderRef.current;
        if (!slider) return;
        gsap.to(slider, {
            scrollTrigger: {
                trigger: slider,
                scrub: 0.3,
            },
            x: "-100%",
            ease: "none",
        });
    }, []);

    const blueRef = React.useRef<SVGCircleElement>(null);
    const tealRef = React.useRef<SVGCircleElement>(null);
    const redRef = React.useRef<SVGCircleElement>(null);
    const heroRef = React.useRef<HTMLDivElement>(null);
    const insightsRef = React.useRef<HTMLDivElement>(null);
    const creativeRef = React.useRef<HTMLDivElement>(null);
    const technologyRef = React.useRef<HTMLDivElement>(null);
    const bodyRef = React.useRef<HTMLDivElement>(null);
    const graphicRef = React.useRef<HTMLDivElement>(null);

    useGSAP(() => {
        const blue = blueRef.current;
        const teal = tealRef.current;
        const red = redRef.current;
        const container = graphicRef.current;
        if (!(blue && teal && red && container)) return;

        gsap.timeline({
            defaults: {
                scrollTrigger: {
                    trigger: heroRef.current,
                    start: "top top",
                    endTrigger: insightsRef.current,
                    end: "center-=10% center",
                    scrub: 0.3,
                },
                svgOrigin: "0 0",
            },
        })
            .to([blue, teal, red], { rotate: "+=120" }, 0)
            .to([blue, teal], { alpha: 0.2 }, 0);

        gsap.timeline({
            defaults: {
                scrollTrigger: {
                    trigger: insightsRef.current,
                    start: "center+=10% center",
                    endTrigger: creativeRef.current,
                    end: "center-=10% center",
                    scrub: 0.3,
                    immediateRender: false,
                },
                svgOrigin: "0 0",
            },
        })
            .to([blue, teal, red], { rotate: "+=120" }, 0)
            .to([red], { alpha: 0.2 }, 0)
            .to([teal], { alpha: 0.7 }, 0);

        gsap.timeline({
            defaults: {
                scrollTrigger: {
                    trigger: creativeRef.current,
                    start: "center+=10% center",
                    endTrigger: technologyRef.current,
                    end: "center-=10% center",
                    scrub: 0.3,
                    immediateRender: false,
                },
                svgOrigin: "0 0",
            },
        })
            .to([blue, teal, red], { rotate: "+=120" }, "three")
            .to([teal], { alpha: 0.2 }, "three")
            .to([blue], { alpha: 0.7 }, "three");

        gsap.timeline({
            defaults: {
                scrollTrigger: {
                    trigger: technologyRef.current,
                    start: "center+=10% center",
                    endTrigger: bodyRef.current,
                    end: "center-=10% center",
                    scrub: 0.3,
                    immediateRender: false,
                },
                svgOrigin: "0 0",
            },
        }).to(
            [blue, teal, red],
            {
                alpha: 0,
                cx: 0,
                cy: 0,
                scale: 0,
                duration: 2,
            },
            "start",
        );

        gsap.timeline({
            defaults: {
                scrollTrigger: {
                    trigger: technologyRef.current,
                    start: "center+=10% center",
                    endTrigger: bodyRef.current,
                    end: "center-=10% center",
                    scrub: 0.3,
                    immediateRender: false,
                },
                svgOrigin: "0 0",
            },
        }).to([container], { alpha: 0 }, "start");
    }, []);

    return (
        <div
            className={concatClassNames([
                props.color_theme ? themes[props.color_theme] : undefined,
                styles.root,
            ])}
        >
            <div className={styles.fixedGraphic} ref={graphicRef}>
                <svg viewBox="-100 -100 200 200">
                    <circle
                        ref={blueRef}
                        cx={0}
                        cy={-50}
                        r={55}
                        fill={colors.blue}
                        className={styles.colorCircle}
                    />
                    <circle
                        ref={tealRef}
                        cx={-cos * 50}
                        cy={sin * 50}
                        r={55}
                        fill={colors.teal}
                        className={styles.colorCircle}
                    />
                    <circle
                        ref={redRef}
                        cx={cos * 50}
                        cy={sin * 50}
                        r={55}
                        fill={colors.red}
                        className={styles.colorCircle}
                    />
                </svg>
            </div>
            <ScrollIndicator />
            <div className={styles.storyWrapper}>
                {props.hero && (
                    <section ref={heroRef} className={styles.storySection}>
                        <RichTextBlock
                            value={props.hero}
                            largeHeadings={true}
                            className={styles.heroRichText}
                        />
                    </section>
                )}
                {props.insights && (
                    <section
                        ref={insightsRef}
                        style={{ "--slide-color": "var(--red)" }}
                        className={styles.storySection}
                    >
                        <h3>{props.insights.label}</h3>
                        {props.insights.body && (
                            <RichTextBlock
                                className={styles.richText}
                                largeHeadings={true}
                                value={props.insights.body}
                            />
                        )}
                    </section>
                )}
                {props.creative && (
                    <section
                        ref={creativeRef}
                        style={{ "--slide-color": "var(--teal)" }}
                        className={styles.storySection}
                    >
                        <h3>{props.creative.label}</h3>
                        {props.creative.body && (
                            <RichTextBlock
                                className={styles.richText}
                                largeHeadings={true}
                                value={props.creative.body}
                            />
                        )}
                    </section>
                )}
                {props.technology && (
                    <section
                        ref={technologyRef}
                        style={{ "--slide-color": "var(--blue)" }}
                        className={styles.storySection}
                    >
                        <h3>{props.technology.label}</h3>
                        {props.technology.body && (
                            <RichTextBlock
                                className={styles.richText}
                                largeHeadings={true}
                                value={props.technology.body}
                            />
                        )}
                    </section>
                )}
                <div ref={globalHeaderRef} />
                {props.body && (
                    <section ref={bodyRef} className={styles.body}>
                        <RichTextBlock value={props.body} />
                    </section>
                )}
                {props.media_slider?.length && (
                    <div className={styles.slider} ref={sliderRef}>
                        {props.media_slider.map((block) => (
                            <MediaChooserBlock
                                key={block.id}
                                value={block.value}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
