import React from "react";

import { AnimatedFeaturedRichTextBlock } from "@reactivated";

import { RichTextBlock as BaseRichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./rich-text.module.scss";

type TerminalMargin = "both" | "none" | "firstChild" | "lastChild";

export interface Props extends React.ComponentProps<typeof BaseRichTextBlock> {
    /**
     * Usually we want the margin-top of the rich text's first child and the
     * margin-bottom of the rich text's last child to be trimmed so they don't
     * affect the layout. But there are some cases where it's useful, so here
     * are a few ways out if you need them.
     */
    trimTerminalMargins?: TerminalMargin;
    /**
     *
     */
    largeHeadings?: boolean;
}

export function RichTextBlock({
    trimTerminalMargins = "both",
    largeHeadings = false,
    ...props
}: Props) {
    return (
        <BaseRichTextBlock
            {...props}
            className={concatClassNames([
                styles.richText,
                largeHeadings ? styles.largeHeadings : "",
                styles[trimTerminalMargins],
                props.className,
            ])}
        />
    );
}

export function FeaturedRichTextBlock(
    props: AnimatedFeaturedRichTextBlock & {
        trimTerminalMargins?: TerminalMargin;
        className?: string | undefined;
    },
) {
    if (!props.content) return null;
    return (
        <div
            className={concatClassNames([
                styles.layout,
                styles[props.layouts!],
                props.className ?? "",
            ])}
        >
            <RichTextBlock
                value={props.content}
                largeHeadings={true}
                trimTerminalMargins={props.trimTerminalMargins}
            />
        </div>
    );
}
