import { useGSAP } from "@gsap/react";
import { gsap } from "gsap/dist/gsap";
import React, {
    ComponentProps,
    PropsWithChildren,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Tab as AriaTab,
    TabList as AriaTabList,
    TabPanel as AriaTabPanel,
    Tabs as AriaTabs,
    TabListStateContext,
} from "react-aria-components";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./Tabs.module.scss";

type Props = PropsWithChildren<ComponentProps<typeof AriaTabList>>;

function TabList({ children, ...props }: Props) {
    const ref = useRef<HTMLDivElement>(null);
    const underlineRef = useRef<HTMLDivElement>(null);
    const state = useContext(TabListStateContext);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (!ref.current) return;
            setIsOverflowing(ref.current.scrollWidth > ref.current.clientWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useGSAP(
        () => {
            const q = gsap.utils.selector(ref.current);
            const activeTab = q(`[data-selected]`)[0];
            const underline = underlineRef.current;
            const list = ref.current;

            const initialUnderlineMovement = () => {
                const activeTab = q(`[data-selected]`)[0];
                const underline = underlineRef.current;
                const list = ref.current;
                if (!activeTab || !underline || !list) return;
                // On initial load, no transition
                gsap.set(underline, {
                    x: activeTab.offsetLeft - list.offsetLeft - list.scrollLeft,
                    width: activeTab.clientWidth,
                });
            };

            const delayedCall = gsap
                .delayedCall(0.1, initialUnderlineMovement)
                .pause();

            window.addEventListener(
                "resize",
                () => void delayedCall.restart(true),
            );

            if (!activeTab || !underline || !list)
                return initialUnderlineMovement;

            list.addEventListener(
                "scroll",
                () => void delayedCall.restart(true),
            );

            const tween = gsap.to(underline, {
                x: activeTab.offsetLeft - list.offsetLeft - list.scrollLeft,
                width: activeTab.clientWidth,
                duration: 0.5,
                ease: "power2.inOut",
            });
            return () => {
                tween.kill();
            };
        },
        { dependencies: [state?.selectedKey] },
    );

    return (
        <div
            className={concatClassNames([
                styles.tabListContainer,
                isOverflowing ? styles.overflowing : "",
            ])}
        >
            <AriaTabList {...props} ref={ref}>
                {children}
            </AriaTabList>
            <div className={styles.underlineContainer}>
                <div ref={underlineRef} className={styles.underline} />
            </div>
        </div>
    );
}

/**
 * @example
 * <Tabs
 *      label="accesible label for module"
 *      tabs={data.map(d => <Tab id={d.id}>{d.value}</Tab>)}
 * >
 *  {data.map(d => <TabPanel id={d.id})>{d.details}</TabPanel>}
 * </Tabs>
 */
export default function Tabs({
    tabs,
    children: panels,
    label,
    className,
}: {
    tabs: ReactNode;
    children: ReactNode;
    label: string;
    className?: string;
}) {
    return (
        <AriaTabs className={concatClassNames([styles.root, className])}>
            <TabList aria-label={label}>{tabs}</TabList>
            {panels}
        </AriaTabs>
    );
}

export const Tab = AriaTab;

export const TabPanel = AriaTabPanel;
