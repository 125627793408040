import ScrollTrigger from "gsap/dist/ScrollTrigger";
import React from "react";

import {
    MediaChooserBlock as MediaProps,
    TabbedBlock as Value,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";

import { RichTextBlock } from "../RichText";
import Tabs, { Tab, TabPanel } from "../Tabs";
import { VideoWithPlayPauseButton } from "../VideoWithPlayPauseButton";

import styles from "./case-studies.module.scss";

const Media = ({ media }: { media: MediaProps }) => {
    if (!media || media.length === 0) return <></>;
    const item = media[0];

    if (item.type === "image")
        return (
            <ImageChooserBlock
                {...item}
                imageAttrs={{
                    className: styles.tabImage,
                    style: {
                        aspectRatio: item.value.width / item.value.height,
                    },
                    onLoad: () => {
                        ScrollTrigger.refresh();
                    },
                }}
            />
        );
    if (item.type === "video")
        return (
            <VideoWithPlayPauseButton
                value={item.value}
                wrapperAttrs={{ className: styles.videoWrapper }}
            />
        );

    return <MediaChooserBlock value={media} />;
};

export default function TabbedBlock(props: Value) {
    if (!props.tabs?.length) return;
    return (
        <Tabs
            className={styles.tabbedBlock}
            tabs={props.tabs.map(({ id, value: tab }) => (
                <Tab key={id} id={id}>
                    {tab.tab_name}
                </Tab>
            ))}
            label={props.title || "Tab Group"}
        >
            {props.tabs.map(({ id, value: tab }) => (
                <TabPanel id={id} key={id}>
                    {tab.media && <Media media={tab.media} />}
                    {tab.caption && (
                        <RichTextBlock
                            className={styles.richText}
                            value={tab.caption}
                        />
                    )}
                </TabPanel>
            ))}
        </Tabs>
    );
}
