import ScrollTrigger from "gsap/dist/ScrollTrigger";
import React from "react";

import { StatsBlock as StatsBlockInterface } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { RichTextBlock } from "../RichText";

import styles from "./StatsBlock.module.scss";

export default function StatsBlock({ ...props }: StatsBlockInterface) {
    return (
        <div className={styles.container}>
            <section>
                <RichTextBlock
                    className={styles.rtf}
                    value={props.header!}
                    largeHeadings={true}
                />
                <ul className={styles.statList}>
                    {props.stats &&
                        props.stats.map((stat, i) => {
                            return (
                                <li className={styles.statContainer} key={i}>
                                    <div className={styles.stat}>
                                        <div>
                                            {stat.large_text}
                                            {stat.small_text && (
                                                <span>{stat.small_text}</span>
                                            )}
                                        </div>
                                        <p>{stat.paragraph}</p>
                                    </div>
                                    {stat.icon && (
                                        <div className={styles.icon}>
                                            <ImageChooserBlock
                                                value={stat.icon}
                                                imageAttrs={{
                                                    className: styles.image,
                                                    onLoad: () => {
                                                        ScrollTrigger.refresh();
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                </ul>
            </section>
        </div>
    );
}
