import themes from "@client/styles/theming.module.css";
import React, { useRef } from "react";

import { MediaFeedBlock as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { notEmptyOrBlank } from "@thelabnyc/thelabui/src/utils/functional";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ListCarousel } from "../Carousel/ListCarousel";
import { ButtonSecondary, Clickable } from "../Clickables";
import { RichTextBlock } from "../RichText";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

type SlideProps = NonNullable<Value["slides"]>[number]["value"];

const Slide = ({ image, alt_text, is_video, url }: SlideProps) => {
    if (!image) return <></>;

    const brightImage = image.brightness > 185;

    const imageBlock = (
        <ImageChooserBlock
            attrs={{ className: styles.imageContainer }}
            value={image}
            imageAttrs={{
                alt: alt_text ?? undefined,
                className: styles.image,
            }}
        />
    );

    const instagramLink = url?.includes("instagram.com") || false;
    const externalUrl =
        typeof window !== "undefined"
            ? !url?.includes(window.location.hostname)
            : true;

    /**
     * The Instagram case requires a darkened overlay with the Instagram logo,
     * but there's also a case where video links get a play icon
     *
     * Assumption: overlay will only ever cover the image
     *
     * Assumption: the blog case would not want a hover overlay but might want
     * the play icon
     */
    const overlay = (
        <div
            className={concatClassNames([
                styles.overlay,
                brightImage ? styles.brightImage : styles.darkImage,
            ])}
            aria-hidden="true"
        >
            {instagramLink && (
                <div className={styles.instagramOverlay} aria-hidden="true">
                    <Svg name="instagram" className={styles.svg} />
                </div>
            )}
            {is_video && (
                <Svg
                    name="play-fill"
                    className={concatClassNames([styles.svg, styles.playIcon])}
                />
            )}
        </div>
    );

    return (
        <div className={styles.slide}>
            {url ? (
                <Clickable
                    href={url}
                    target={externalUrl ? "_blank" : "_self"}
                    className={styles.clickable}
                >
                    {imageBlock}
                    {overlay}
                </Clickable>
            ) : (
                <>
                    {image}
                    {overlay}
                </>
            )}
        </div>
    );
};

export const MediaFeedBlock = ({
    color_theme,
    cta_text,
    cta_link,
    slides,
    title,
}: Value) => {
    const carouselWrapper = useRef<HTMLDivElement>(null);
    if (!slides) return <></>;

    /**
     * Possibly code-smelly, but the design asked for an Instagram link with the
     * Instagram icon and I wanted there to be an easy out in case that changed
     * down the line
     */
    const instagramSpecialCase =
        cta_link?.display_name?.toLowerCase().includes("instagram") ?? false;

    return (
        <section className={color_theme ? themes[color_theme] : undefined}>
            <div className={styles.inner} ref={carouselWrapper}>
                <RichTextBlock
                    className={styles.heading}
                    largeHeadings={true}
                    value={title ?? ""}
                />

                <ListCarousel
                    label="Media feed"
                    navigationProps={{
                        style: "filled",
                        className: styles.carouselNav,
                    }}
                    wrapperAttrs={{ className: styles.carousel }}
                    listItemAttrs={{ className: styles.listItem }}
                >
                    {slides.filter(notEmptyOrBlank).map((slide) => (
                        <Slide {...slide.value} key={slide.id} />
                    ))}
                </ListCarousel>
                {cta_link || cta_text ? (
                    <div className={styles.cta}>
                        <RichTextBlock value={cta_text ?? ""} />
                        {cta_link ? (
                            <ButtonSecondary
                                href={cta_link.url ?? undefined}
                                target={
                                    cta_link.link_type === "external"
                                        ? "_blank"
                                        : "_self"
                                }
                                noConditionalIcon={instagramSpecialCase}
                                icon="instagram"
                            >
                                {cta_link.display_name}
                            </ButtonSecondary>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </section>
    );
};
