import breakpoints from "./breakpoint.module.scss";

/**
 * For each breakpoint, we want two media queries: one below the breakpoint
 * and one above.
 */
type AboveAndBelowBreakpoint = {
    [K in keyof typeof breakpoints as
        | `below${Capitalize<string & K>}`
        | `above${Capitalize<string & K>}`]: string;
};

/**
 * Coerces exported breakpoint variables into a set of media queries that mirror
 * what we use on the Sass side.
 *
 * Queries are wrapped in parentheses to handle the anticipated use case of
 * `buildUseCurrentBreakpointHook`.
 *
 * This exists because exporting from the module.scss file yields a string wrapped
 * in quotes because of the colon, and those quotes would have needed processed
 * on the JS side anyways.
 *
 * The type safety isn't bulletproof and TS doesn't like Object.assign, but it
 * does yield autocomplete of things like `aboveTablet` when it's time to use it.
 */
// eslint-disable-next-line
export const mediaQueries: AboveAndBelowBreakpoint = Object.assign(
    {},
    ...Object.entries(breakpoints).map(([key, value]) => {
        const capitalKey = key.charAt(0).toUpperCase() + key.slice(1);
        return {
            [`below${capitalKey}`]: `(max-width: ${parseInt(value) - 1}px)`,
            [`above${capitalKey}`]: `(min-width: ${value})`,
        };
    }),
);
