import { useGSAP } from "@gsap/react";
import { gsap } from "gsap/dist/gsap";
import React, { useRef } from "react";

import { useCurrentBreakpoint } from "../../utils";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export const ScrollIndicator = () => {
    const ref = useRef<HTMLSpanElement>(null);
    const viewport = useCurrentBreakpoint();

    useGSAP(() => {
        const element = ref.current;
        if (!element) return;
        gsap.to(element, {
            scrollTrigger: {
                trigger: element,
                start: () =>
                    window.innerHeight - element.getBoundingClientRect().bottom,
                end: "+=250",
                scrub: 0.3,
            },
            alpha: 0,
            ease: "none",
        });
    }, []);

    return (
        <span ref={ref} className={styles.scrollIndicator}>
            {viewport.aboveMobile && <>Scroll</>}
            <Svg name="caret-down" />
        </span>
    );
};
