import React from "react";

function drawCircle(cx: number, cy: number, r: number, half = false) {
    return `
    M${cx - r} ${cy}
    a1 1 0 0 1 ${r * 2} 0
    ${!half ? `a1 1 0 0 1 ${-r * 2} 0` : `l${-r * 2} 0`}
    Z
    `;
}

export const LabLogo = React.forwardRef<
    SVGPathElement,
    {
        r?: number;
        cx?: number;
        cy?: number;
        hillMask?: boolean;
    } & React.SVGAttributes<SVGGElement>
>(function LabLogo(
    { r = 100, cx = 0, cy = 0, hillMask = false, ...props },
    ref,
) {
    const scale = r / 100;
    const h = scale * 77.5;
    const cr = scale * 22.5;
    return (
        <path
            {...props}
            ref={ref}
            d={
                Array(8)
                    .fill(null)
                    .map((_, i) => {
                        const { PI, cos, sin } = Math;
                        const a = (i * PI) / 4;
                        const x = cos(a) * h + cx;
                        const y = sin(a) * h + cy;
                        return drawCircle(x, y, cr, hillMask && 0 < i && i < 4);
                    })
                    .join("") + drawCircle(cx, cy, cr)
            }
        />
    );
});
