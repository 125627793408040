import { useCurrentBreakpoint } from "@client/utils";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import React from "react";

import { HeroBlock } from "@reactivated";

import { RichTextBlock } from "../RichText";
import { ScrollIndicator } from "../ScrollIndicator";
import AnimatedHeroLogo from "./AnimatedHeroLogo";

import animationStyles from "./animated-hero-logo.module.scss";
import homepageStyles from "./homepage.module.scss";

export default function HomePageHeroBlock(props: HeroBlock) {
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const viewport = useCurrentBreakpoint();

    useGSAP(
        () => {
            const scrollTrigger = ScrollTrigger.create({
                trigger: wrapperRef.current,
                start: "bottom top",
                onRefresh: (state) => {
                    const animation = document.querySelector(
                        `.${animationStyles.container}`,
                    ) as HTMLDivElement;
                    if (viewport.belowMobile) {
                        animation.style.position = "static";
                    } else {
                        if (state.progress === 0) {
                            animation.style.position = "fixed";
                        } else {
                            animation.style.position = "relative";
                        }
                    }
                },
                onEnter: () => {
                    if (viewport.belowMobile) return;
                    const animation = document.querySelector(
                        `.${animationStyles.container}`,
                    ) as HTMLDivElement;
                    animation.style.position = "relative";
                },
                onLeaveBack: () => {
                    if (viewport.belowMobile) return;
                    const animation = document.querySelector(
                        `.${animationStyles.container}`,
                    ) as HTMLDivElement;
                    animation.style.position = "fixed";
                },
            });

            return () => {
                scrollTrigger.kill();
            };
        },
        { dependencies: [viewport.belowMobile], revertOnUpdate: true },
    );

    return (
        <div ref={wrapperRef} className={homepageStyles.hero}>
            <AnimatedHeroLogo />
            <RichTextBlock
                className={homepageStyles.title}
                largeHeadings={true}
                value={props.subtitle ?? ""}
            />
            <ScrollIndicator />
        </div>
    );
}
