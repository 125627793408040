import { ButtonPrimary } from "@client/components/Clickables";
import Widget from "@client/components/FormField";
import React from "react";
import { Form } from "react-aria-components";

import {
    CmsModelsPagesContactUsPageContactUsEmbedProps,
    Context,
    reverse,
} from "@reactivated";

import { AlignedRichTextSubBlock } from "@thelabnyc/thelabui/src/components/AlignedRichTextSubBlock";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { CSRF_HEADER, loadCSRFToken } from "@thelabnyc/thelabui/src/utils/api";

import styles from "./ContactUsFormContent.module.scss";

const submitForm = async (
    page: CmsModelsPagesContactUsPageContactUsEmbedProps,
    formData: FormData,
): Promise<Response> => {
    const submitURL = reverse("submit-form", {
        page_id: page.id,
    });
    const csrfToken = await loadCSRFToken();
    const response = await fetch(submitURL, {
        method: "POST",
        headers: [
            ["Accept", "application/json"],
            [CSRF_HEADER, csrfToken],
        ],
        body: formData,
    });
    return response;
};

type Props = { defaultIsSubmitted: boolean };

type Errors = {
    [name: string]: string[];
};

export const ContactUsFormContent = (props: Props) => {
    const { contact_form, contact_form_page } = React.useContext(Context);
    const [validationErrors, setValidationErrors] = React.useState<Errors>();
    const [submitted, setSubmitted] = React.useState(props.defaultIsSubmitted);
    const [loading, setLoading] = React.useState(false);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const data = new FormData(e.currentTarget);
            setLoading(true);
            const response = await submitForm(contact_form_page!, data);
            setLoading(false);
            if (response.ok) {
                setSubmitted(true);
            } else {
                setValidationErrors(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    await response.json(),
                );
            }
        },
        [contact_form_page?.id],
    );

    if (!contact_form || !contact_form_page) {
        return null;
    }

    if (submitted) {
        return (
            <StreamField
                value={contact_form_page.lp_body.value}
                components={{
                    rich_text: AlignedRichTextSubBlock,
                }}
            />
        );
    }
    return (
        <>
            <StreamField
                value={contact_form_page.body.value}
                components={{
                    rich_text: AlignedRichTextSubBlock,
                }}
            />
            <Form
                method="post"
                className={styles.form}
                validationErrors={validationErrors}
                onSubmit={onSubmit}
            >
                <input
                    type="hidden"
                    name="form_name"
                    value={contact_form.name}
                />
                {contact_form.iterator.map((fieldName) => (
                    <Widget
                        key={fieldName}
                        {...contact_form.fields[fieldName]}
                    />
                ))}
                <ButtonPrimary
                    style={{ alignSelf: "flex-start" }}
                    loading={loading}
                    type="submit"
                    icon="arrow"
                >
                    Submit
                </ButtonPrimary>
            </Form>
        </>
    );
};
