import TabbedBlock from "@client/components/CaseStudies/TabbedBlock";
import { PageSkeleton } from "@client/components/PageSkeleton";
import { useGSAP } from "@gsap/react";
import Flip from "gsap/dist/Flip";
import GSDevTools from "gsap/dist/GSDevTools";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import React from "react";

import colors from "../styles/color-variables.module.scss";
import fontStyles from "../styles/composable/typography.module.css";
import styles from "../styles/storybook.module.scss";

import "../styles/global.scss";

const lorem =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua.";

let x = 0;
function getPlaceholder(width: number, height: number) {
    return `https://${
        ["placekeanu.com", "picsum.photos", "placebear.com", "placebeard.it"][
            x++ % 4
        ]
    }/${width}/${height}`;
}

gsap.registerPlugin(ScrollTrigger, GSDevTools, Flip);

function drawCircle(cx: number, cy: number, r: number, half = false) {
    return `
    M${cx - r} ${cy}
    a1 1 0 0 1 ${r * 2} 0
    ${!half ? `a1 1 0 0 1 ${-r * 2} 0` : `l${-r * 2} 0`}
    Z
    `;
}
const LabLogo = React.forwardRef<
    SVGPathElement,
    {
        r?: number;
        cx?: number;
        cy?: number;
        hillMask?: boolean;
    } & React.SVGAttributes<SVGGElement>
>(function LabLogo(
    { r = 100, cx = 0, cy = 0, hillMask = false, ...props },
    ref,
) {
    const scale = r / 100;
    const h = scale * 77.5;
    const cr = scale * 22.5;
    return (
        <path
            {...props}
            ref={ref}
            d={
                Array(8)
                    .fill(null)
                    .map((_, i) => {
                        const { PI, cos, sin } = Math;
                        const a = (i * PI) / 4;
                        const x = cos(a) * h + cx;
                        const y = sin(a) * h + cy;
                        return drawCircle(x, y, cr, hillMask && 0 < i && i < 4);
                    })
                    .join("") + drawCircle(cx, cy, cr)
            }
        />
    );
});

export const Template = () => {
    const platform = React.useRef<SVGPathElement>(null);
    const marketing = React.useRef<SVGPathElement>(null);
    const content = React.useRef<SVGPathElement>(null);
    const logo = React.useRef<SVGPathElement>(null);
    const logoMarketing = React.useRef<SVGPathElement>(null);
    const logoContent = React.useRef<SVGPathElement>(null);

    useGSAP(() => {
        const tl = gsap.timeline({
            defaults: { duration: 2, delay: 2, svgOrigin: "0 0" },
            repeat: -1,
            repeatDelay: 2,
        });
        // prettier-ignore
        tl  .to(platform.current,   { alpha: 0 },                                       "marketing")
            .to(marketing.current,  { alpha: 1 },                                       "marketing")
            .to(logo.current,       { fill: colors.blue, duration: 1 },                 "marketing")
            .to(logo.current,       { morphSVG: logoMarketing.current ?? undefined },   "marketing")
            .to(marketing.current,  { alpha: 0 },                                       "content")
            .to(content.current,    { alpha: 1 },                                       "content")
            .to(logo.current,       { fill: colors.red, duration: 1 },                  "content")
            .to(logo.current,       { morphSVG: logoContent.current ?? undefined },     "content");

        return () => {
            tl.kill();
        };
    }, []);

    return (
        <PageSkeleton rootClassName={styles.storybook} title="Design library">
            <section
                style={{
                    minHeight: "100vh",
                    paddingTop: "20vh",
                    background: "var(--color-primary)",
                }}
            >
                <TabbedBlock
                    title="I'm Just Keanu"
                    tabs={Array(4)
                        .fill(null)
                        .map((_, i) => {
                            const width = 1312;
                            const height = 560;
                            const url = getPlaceholder(width, height);
                            return {
                                id: String(i),
                                type: "media",
                                value: {
                                    tab_name: "tab name",
                                    media: [
                                        {
                                            type: "image",
                                            id: String(i),
                                            value: {
                                                title: "keanu",
                                                width,
                                                height,
                                                url,
                                                brightness: 0,
                                                renditions: {
                                                    default: {
                                                        spec: "",
                                                        size: 100,
                                                        mimetype: "",
                                                        width,
                                                        height,
                                                        url,
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                    body: lorem,
                                },
                            };
                        })}
                />
            </section>
            <section style={{ minHeight: "100vh" }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-100 -100 200 200"
                    width="500"
                    id="platform"
                >
                    <path
                        ref={platform}
                        d="M-98 0a1 1 0 00124 0 1 1 0 00-124 0ZM16.5-33.5h81M16.5 33.5h81M-10 57l24 41M10 40.5h-50a1 1 0 010-81h50"
                        stroke="black"
                        fill="none"
                        strokeWidth={2}
                        strokeLinecap="round"
                    />
                    <path
                        ref={marketing}
                        d="M-32.5 89v-25c5-40 60-40 65 0v25M-70 70v-25c2-35 51-43 62.5-10.5M70 70v-25c-2-35-51-43-62.5-10.5M-32.5 89Z"
                        stroke="black"
                        fill="none"
                        strokeWidth={2}
                        strokeLinecap="round"
                        opacity={0}
                    />
                    <path
                        ref={content}
                        d="M-87.5 0c55 75 120 75 175 0-55-75-120-75-175 0Z"
                        stroke="black"
                        fill="none"
                        strokeWidth={2}
                        strokeLinejoin="round"
                        opacity={0}
                    />
                    <LabLogo ref={logo} cx={-36} r={28} fill="var(--teal)" />
                    <defs>
                        <LabLogo
                            ref={logoMarketing}
                            cy={-30}
                            r={57}
                            fill="var(--blue)"
                            hillMask
                        />
                        <LabLogo
                            ref={logoContent}
                            id="redgo"
                            r={35}
                            fill="var(--red)"
                        />
                    </defs>
                </svg>
            </section>
            <section>
                <h1 className={fontStyles.fontHeader128i}>Typography:</h1>
                {Object.entries(fontStyles)
                    .filter(([key, _]) => key.startsWith("font"))
                    .map(([key, value]) => {
                        return (
                            <p key={key} className={value}>
                                {key}
                            </p>
                        );
                    })}
            </section>
            <section>
                <h1 className={fontStyles.fontHeader128i}>Typography:</h1>
                {Object.entries(fontStyles)
                    .filter(([key, _]) => key.startsWith("font"))
                    .map(([key, value]) => {
                        return (
                            <p key={key} className={value}>
                                {key}
                            </p>
                        );
                    })}
            </section>
            <section>
                <h1 className={fontStyles.fontHeader128i}>Typography:</h1>
                {Object.entries(fontStyles)
                    .filter(([key, _]) => key.startsWith("font"))
                    .map(([key, value]) => {
                        return (
                            <p key={key} className={value}>
                                {key}
                            </p>
                        );
                    })}
            </section>
            <section>
                <h1 className={fontStyles.fontHeader128i}>Typography:</h1>
                {Object.entries(fontStyles)
                    .filter(([key, _]) => key.startsWith("font"))
                    .map(([key, value]) => {
                        return (
                            <p key={key} className={value}>
                                {key}
                            </p>
                        );
                    })}
            </section>
            <section>
                <h1 className={fontStyles.fontHeader128i}>Typography:</h1>
                {Object.entries(fontStyles)
                    .filter(([key, _]) => key.startsWith("font"))
                    .map(([key, value]) => {
                        return (
                            <p key={key} className={value}>
                                {key}
                            </p>
                        );
                    })}
            </section>

            <section className={styles.gridSection}>
                <h1 className={fontStyles.fontHeader128}>Grids</h1>
                <div className={styles.grid}>
                    {Array(12)
                        .fill("")
                        .map((_, i) => (
                            <p key={i}>{i + 1}</p>
                        ))}
                </div>
                <div className={styles.container}>
                    <p>this should be full width</p>
                </div>
                <div>
                    <p>this should be full bleed</p>
                </div>
            </section>
        </PageSkeleton>
    );
};
