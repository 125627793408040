import React from "react";

import { AnimatedSpacerBlock as Value } from "@reactivated";

import {
    concatClassNames,
    getStyleVariantClass,
} from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

interface Props {
    value: Omit<Value, "time">;
}

export const SpacerBlock = ({ value }: Props) => {
    const spacerStyle = getStyleVariantClass(styles, value.height);
    const backgroundStyle = getStyleVariantClass(styles, "transparent");

    return value.anchor ? (
        <a
            id={value.anchor}
            className={concatClassNames([spacerStyle, backgroundStyle])}
            data-spacer-block
        />
    ) : (
        <div
            aria-hidden="true"
            className={concatClassNames([spacerStyle, backgroundStyle])}
            data-spacer-block
        />
    );
};
