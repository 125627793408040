import React from "react";
import { createPortal } from "react-dom";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

/**
 * This component's overlay uses mix-blend-mode, and in order for that to work
 * correctly it needs to be outside of the modal.
 */
export const VisualOverlay = ({ isOpen }: { isOpen: boolean }) => {
    if (typeof document === "undefined") return;

    return createPortal(
        <div
            className={concatClassNames([
                styles.contactFormOverlay,
                isOpen ? styles.isOpen : "",
            ])}
        />,
        document.body,
    );
};
