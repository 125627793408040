import ScrollTrigger from "gsap/dist/ScrollTrigger";
import React from "react";

import {
    ImageChooserBlock as ImageChooserBlockValue,
    IconGridBlock as Value,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

function IconGridCopy(props: {
    id?: string;
    type?: "copy";
    index?: number;
    value: string;
}) {
    return <p className={styles.copy}>{props.value}</p>;
}

function IconGridIcon(props: {
    id?: string;
    type?: "icon";
    index?: number;
    value: ImageChooserBlockValue;
}) {
    return (
        <ImageChooserBlock
            attrs={{
                className: styles.image,
            }}
            imageAttrs={{
                onLoad: () => {
                    ScrollTrigger.refresh();
                },
            }}
            value={props.value}
        />
    );
}

export default function IconGridBlock(props: Props) {
    if (!props.value.items) {
        return null;
    }
    return (
        <section className={styles.wrapper}>
            <StreamField
                attrs={{
                    className: styles.container,
                }}
                value={props.value.items}
                components={{
                    copy: (block) => <IconGridCopy {...block} />,
                    icon: (block) => <IconGridIcon {...block} />,
                }}
            />
        </section>
    );
}
