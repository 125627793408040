import { useGSAP } from "@gsap/react";
import { gsap } from "gsap/dist/gsap";
import React from "react";

import { CaseStudyGridBlock as Value, _CaseStudy } from "@reactivated";

import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

const CaseStudy = (props: _CaseStudy) => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    useGSAP(() => {
        gsap.to(containerRef.current, {
            scrollTrigger: {
                trigger: containerRef.current,
                start: "top 70%",
                end: "top top",
                scrub: true,
            },
            padding: `16px`,
        });
    });

    return (
        <div ref={containerRef}>
            {props.media && <MediaChooserBlock value={props.media} />}
            <div className={styles.description}>
                <h4 className={styles.heading}>{props.heading}</h4>
                <h5 className={styles.client}>{props.client}</h5>
                <ul className={styles.workTypes}>
                    {props.work_types?.map((type, i) => {
                        return (
                            <li key={i} className={styles.tag}>
                                {type.value}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default function CaseStudyGridBlock(props: Props) {
    return (
        <section className={styles.root}>
            {props.value.studies?.map((block, i) => {
                return <CaseStudy key={i} {...block.value} />;
            })}
        </section>
    );
}
