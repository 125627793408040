import React from "react";
import {
    Button,
    Label,
    ListBox,
    ListBoxItem,
    Popover,
    Select,
    SelectProps,
    SelectValue,
    Text,
} from "react-aria-components";

import clickableStyles from "@thelabnyc/thelabui/src/components/Clickable/Clickable.module.scss";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";
import { CommonFieldProps, FieldError } from "./utils";

import styles from "./inputs.module.scss";

export function SelectField({
    label,
    description,
    errorMessage,
    children,
    className,
    ...props
}: React.PropsWithChildren<
    CommonFieldProps & Omit<SelectProps<object>, "children">
>) {
    return (
        <Select
            className={concatClassNames([
                typeof className === "string" ? className : undefined, //React-Aria does some fancy function stuff I'm ignoring for now
                styles.dropdown,
            ])}
            placeholder="Select" // default
            {...props}
        >
            <div className={styles.fieldWrapper}>
                <Label>{label}</Label>
                <Button className={clickableStyles.buttonReset}>
                    <SelectValue />
                    <Svg aria-hidden name="caret-down" />
                </Button>
            </div>
            {description && <Text slot="description">{description}</Text>}
            <FieldError>{errorMessage}</FieldError>
            <Popover>
                <ListBox className={styles.dropdownList}>{children}</ListBox>
            </Popover>
        </Select>
    );
}

export const SelectOption = ListBoxItem;
