import React from "react";

import { FeaturedRichTextBlock } from "../RichText";

import styles from "./index.module.scss";

interface Props {
    richtext: string | undefined;
    client: string;
}

export const CaseStudyHero = (props: Props) => (
    <section className={styles.heroText}>
        <FeaturedRichTextBlock
            layouts="left"
            content={props.richtext ?? ""}
            time={0}
            className={styles.richTextBlock}
        />
        <div className={styles.heroClient}>{props.client}</div>
    </section>
);
