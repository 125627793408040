import { FullWidthMedia } from "@client/components/FullWidthMedia";
import { PageSkeleton } from "@client/components/PageSkeleton";
import { SpacerBlock } from "@client/components/SpacerBlock";
import StatsBlock from "@client/components/StatsBlock";
import LocationsBlock from "@client/components/services/LocationsBlock";
import StoryBlock from "@client/components/services/StoryBlock";
import React from "react";

import { templates } from "@reactivated";

import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";

export const Template = function ServicesPageTemplate({
    page,
}: templates.ServicesPageTemplate) {
    const ref = React.useRef<HTMLDivElement>(null);
    return (
        <PageSkeleton {...page} fixedLogoFadeInRef={ref}>
            <StreamField
                tagName={React.Fragment}
                value={page.body.value}
                components={{
                    hero_image: FullWidthMedia,
                    story: (block) => (
                        <StoryBlock value={block.value} globalHeaderRef={ref} />
                    ),
                    locations: (block) => <LocationsBlock {...block.value} />,
                    media: (block) => {
                        if (block.value[0].type === "video") {
                            return (
                                <VideoChooserBlock
                                    iOSFriendlyMutedAutoPlay={true}
                                    attrs={{
                                        loop: true,
                                        controls: false,
                                    }}
                                    value={block.value[0].value}
                                />
                            );
                        }

                        return <MediaChooserBlock value={block.value} />;
                    },
                    stats: (block) => <StatsBlock {...block.value} />,
                    spacer: SpacerBlock,
                }}
            />
        </PageSkeleton>
    );
};
