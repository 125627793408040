import ContactUsForm from "@client/components/ContactUsForm";
import { PageSkeleton } from "@client/components/PageSkeleton";
import React from "react";

import { Context, Provider, templates } from "@reactivated";

import { ButtonPrimary } from "../components/Clickables";

export const Template = function ContactUsLP(
    props: templates.ContactUsLPTemplate,
) {
    if (!props.is_preview)
        throw new Error(
            "This page type should never render outside of preview",
        );
    const context = React.useContext(Context);
    return (
        <>
            <Provider
                value={{
                    ...context,
                    contact_form_page: props.page,
                }}
            >
                <ContactUsForm defaultIsOpen={true} defaultIsSubmitted={true}>
                    <ButtonPrimary>Hello preview</ButtonPrimary>
                </ContactUsForm>
            </Provider>
            <PageSkeleton {...props.page}>
                <div style={{ minHeight: "100vh" }} />
            </PageSkeleton>
        </>
    );
};
