import { PageSkeleton } from "@client/components/PageSkeleton";
import React from "react";

import { templates } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import IconGridBlock from "../components/IconGrid";
import JobListBlock from "../components/JobList";
import LPHeroBlock from "../components/LPHero";
import QuotesBlock from "../components/Quotes";
import { FeaturedRichTextBlock } from "../components/RichText";
import ShowcaseBlock from "../components/ShowcaseBlock";
import { SpacerBlock } from "../components/SpacerBlock";

export const Template = (props: templates.CareersPageTemplate) => {
    const logoFadeInRef = React.useRef<HTMLDivElement>(null);

    return (
        <React.StrictMode>
            <PageSkeleton {...props.page} fixedLogoFadeInRef={logoFadeInRef}>
                <StreamField
                    tagName={React.Fragment}
                    value={props.page.body.value}
                    components={{
                        hero: (block) => (
                            <>
                                <div ref={logoFadeInRef} />
                                <LPHeroBlock {...block.value} />
                            </>
                        ),
                        job_list: (block) => <JobListBlock {...block.value} />,
                        icon_grid: (block) => <IconGridBlock {...block} />,
                        rich_text: (block) => (
                            <FeaturedRichTextBlock {...block.value} time={0} />
                        ),
                        quotes: (block) => <QuotesBlock {...block.value} />,
                        showcase: (block) => <ShowcaseBlock {...block.value} />,
                        spacer: (block) => <SpacerBlock value={block.value} />,
                    }}
                />
            </PageSkeleton>
        </React.StrictMode>
    );
};
