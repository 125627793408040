import { createContext, useEffect, useState } from "react";

import { buildUseCurrentBreakpointHook } from "@thelabnyc/thelabui/src/hooks/layout";

import { mediaQueries } from "./styles/media-queries";

/**
 * Hooks for components which need to react ot the current breakpoint.
 */
export const useCurrentBreakpoint = buildUseCurrentBreakpointHook(mediaQueries);

/**
 * Context used to pass down common page properties to downstream components
 */
export const pageContext = createContext<{
    title?: string;
    seoTitle?: string;
    searchDescription?: string;
}>({});

export const PageProvider = pageContext.Provider;

let isClient = false;

// The initial render on the client has to match the server to avoid
// hydration errors, so instead of checking if `typeof window === 'undefined'`
// we leave it false until `useEffect` fires for the first time
export function useIsClient() {
    const [isClientState, setIsClient] = useState(isClient);
    useEffect(() => {
        if (isClientState) return;
        isClient = true;
        setIsClient(true);
    }, []);
    return isClientState;
}
