import React, { forwardRef } from "react";
import { AriaButtonOptions, useButton, useObjectRef } from "react-aria";

import { Clickable as BaseClickable } from "@thelabnyc/thelabui/src/components/Clickable";
import {
    IconMap,
    createConditionalIcons,
} from "@thelabnyc/thelabui/src/components/Clickable/ConditionalIcons";
import { ClickableProps as BaseClickableProps } from "@thelabnyc/thelabui/src/components/Clickable/types";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { SVGName, Svg, allSvgNames } from "../Svg";

import styles from "./index.module.scss";

const conditionalIconMap: IconMap<SVGName> = {
    download: "download",
    external: "external",
};

const ConditionalIcons = createConditionalIcons(allSvgNames, Svg);

export type ClickableProps = BaseClickableProps &
    AriaButtonOptions<"button" | "a"> & {
        noConditionalIcon?: boolean;
    };

export const Clickable = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>((props, ref) => {
    ref = useObjectRef(ref);
    const { buttonProps } = useButton(props, ref);
    const { onPress, noConditionalIcon, ...propsWithoutOddballs } = props;

    return (
        <BaseClickable ref={ref} {...buttonProps} {...propsWithoutOddballs}>
            {props.children}
        </BaseClickable>
    );
});

type InnerProps = ClickableProps & {
    noConditionalIcon?: boolean;
    underline?: boolean;
};

const Inner = ({ noConditionalIcon = false, ...props }: InnerProps) => {
    const conditionalIconProps = {
        ...props,
        mappedConditionalIcons: conditionalIconMap,
    };

    const icon = noConditionalIcon ? (
        typeof props.icon === "string" ? (
            <Svg
                name={props.icon as SVGName}
                visuallyHiddenText={null}
                aria-hidden={true}
            />
        ) : null
    ) : (
        ConditionalIcons({ ...conditionalIconProps })
    );

    return (
        <div className={styles.clickableContent}>
            {props.children}
            {props.icon === "arrow" ? (
                <div aria-hidden={true} className={styles.arrowSpecialWrapper}>
                    <div className={styles.arrowSpecial} />
                </div>
            ) : (
                icon
            )}
        </div>
    );
};

export const ButtonPrimary = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ButtonPrimary(props, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.primaryButton,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} />
        </Clickable>
    );
});

export const ButtonSecondary = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ButtonSecondary(props, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.buttonSecondary,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} underline={true} />
        </Clickable>
    );
});

export const ButtonTertiary = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ButtonTertiary(props, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.buttonTertiary,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} underline={true} />
        </Clickable>
    );
});

export const ButtonPlayPause = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ButtonPlayPause({ ...props }, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.playPauseButton,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} />
        </Clickable>
    );
});
