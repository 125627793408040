import React from "react";

import { JobListBlock as Value } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { ButtonSecondary } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

interface IJobListing {
    id: number;
    title: { id: null; label: string };
    postedDate: string;
    location: {
        id: number | null;
        label: string;
        address: {
            name: string | null;
            description: string | null;
            addressLine1: string | null;
            addressLine2: string | null;
            city: string | null;
            state: string | null;
            zipcode: string | null;
            country: string | null;
            phoneNumber: string | null;
        };
    };
    department: {
        id: number;
        label: string;
    };
    status: {
        id: number;
        label: string;
    };
    newApplicantsCount: number;
    activeApplicantsCount: number;
    totalApplicantsCount: number;
    postingUrl: string;
}

const JobListing = (props: IJobListing) => (
    <li>
        <Clickable
            className={styles.job}
            href={props.postingUrl}
            target="_blank"
        >
            <h3 className={styles.jobTitle}>{props.title.label}</h3>
            <p className={styles.location}>
                {props.location.address.city
                    ? props.location.address.city
                    : "Remote"}
            </p>
            <Svg className={styles.arrow} name="arrow-right" />
        </Clickable>
    </li>
);

export default function JobListBlock(props: Value) {
    return (
        <section className={styles.container}>
            <h2 className={styles.heading}>
                Job Openings{" "}
                {props.listings.length > 0 && (
                    <span className={styles.jobCount}>
                        {props.listings.length}
                    </span>
                )}
            </h2>
            {props.listings.length > 0 && (
                <ul className={styles.listings}>
                    {props.listings.map((listing, i) => (
                        <JobListing key={i} {...listing} />
                    ))}
                </ul>
            )}
            <div className={styles.disclaimer}>
                <p>Don&apos;t see your role here? Send us your resume.</p>
                <ButtonSecondary
                    className={styles.sendButton}
                    icon="arrow"
                    href="mailto:info@thelabnyc.com"
                >
                    Send Resume{" "}
                </ButtonSecondary>
            </div>
        </section>
    );
}
