import React, { useEffect, useRef, useState } from "react";
import { VisuallyHidden } from "react-aria";
import { Dialog, DialogTrigger, Modal } from "react-aria-components";
import { useScrollDirection } from "react-use-scroll-direction";

import {
    Context,
    HeaderNavigationBodyBlock as HeaderNavigationBodyBlockValue,
} from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import {
    ButtonPrimary,
    ButtonSecondary,
    ButtonTertiary,
    Clickable,
} from "../Clickables";
import ContactUsForm from "../ContactUsForm";
import ScrollProgressBar from "../ScrollProgressBar";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

interface IProps {
    value: HeaderNavigationBodyBlockValue;
    themeOverride?: string;
}

const truthyPartialPathMatch = (path: string, linkUrl: string | null) => {
    return linkUrl ? path.includes(linkUrl) : false;
};

export const brightnessTippingPoint = 150;

export const HeaderNavigation = (props: IProps) => {
    const {
        request: { path },
        contact_form_page,
    } = React.useContext(Context);

    const contact_url = contact_form_page?.url;
    const topNavRef = useRef<HTMLDivElement | null>(null);

    const [showNav, setShowNav] = useState(true);
    const [navBG, setNavBG] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [marginTop, setMarginTop] = useState(0);

    const { scrollDirection } = useScrollDirection();

    const onResize = () => {
        setMarginTop(
            mobileMenuActive && topNavRef.current
                ? topNavRef.current.clientHeight - 1
                : 0,
        );
    };

    useEffect(() => {
        onResize();
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [mobileMenuActive, topNavRef]);

    useEffect(() => {
        const hideSticky = window.scrollY > 1;

        if (hideSticky) {
            if (scrollDirection === "DOWN") setShowNav(false);
            setNavBG(true);
        } else {
            setNavBG(false);
        }

        if (scrollDirection === "UP") setShowNav(true);
    }, [scrollDirection]);

    useEffect(() => {
        setNavBG(mobileMenuActive);
    }, [mobileMenuActive]);

    return (
        <nav
            aria-label="Main Menu"
            className={concatClassNames([
                styles.root,
                !showNav ? styles.rootHide : null,
                props.themeOverride ?? "",
            ])}
        >
            <div
                className={concatClassNames([
                    styles.topNav,
                    navBG ? styles.navBackground : null,
                ])}
                ref={topNavRef}
            >
                <Clickable className={styles.logoClickable} href="/">
                    <Svg
                        name={"logo"}
                        visuallyHiddenText="TheLab NYC Home Page"
                        className={styles.logo}
                    />
                </Clickable>

                <div className={styles.desktopMenu}>
                    <StreamField
                        components={{
                            menu_item: ({ value }) => (
                                <li>
                                    <ButtonSecondary
                                        href={value.url ?? undefined}
                                        className={
                                            truthyPartialPathMatch(
                                                path,
                                                value.url,
                                            )
                                                ? styles.pathActive
                                                : ""
                                        }
                                    >
                                        {value.display_name ?? value.url}
                                    </ButtonSecondary>
                                </li>
                            ),
                        }}
                        value={props.value}
                        tagName="ul"
                        attrs={{ className: styles.desktopMenuList }}
                    />
                </div>
                <ContactUsForm>
                    <ButtonPrimary className={styles.desktopConnect}>
                        Connect
                    </ButtonPrimary>
                </ContactUsForm>

                <DialogTrigger
                    onOpenChange={(state) => setMobileMenuActive(state)}
                >
                    <ButtonSecondary className={styles.mobileMenuTrigger}>
                        {mobileMenuActive ? "Close" : "Menu"}
                    </ButtonSecondary>
                    <Modal
                        isDismissable
                        className={concatClassNames([
                            styles.mobileMenu,
                            props.themeOverride ?? "",
                        ])}
                        style={{
                            top: marginTop,
                            height: `calc(var(--visual-viewport-height) - ${marginTop}px)`,
                        }}
                    >
                        <Dialog className={styles.dialog}>
                            <StreamField
                                components={{
                                    menu_item: ({ value }) => (
                                        <li>
                                            <Clickable
                                                className={
                                                    styles.mobileMenuLink
                                                }
                                                href={value.url ?? undefined}
                                            >
                                                {value.display_name ??
                                                    value.url}
                                                <Svg name="arrow-right" />
                                            </Clickable>
                                        </li>
                                    ),
                                }}
                                value={props.value}
                                tagName="ul"
                                attrs={{
                                    className: styles.mobileMenuList,
                                }}
                            />
                            <Clickable
                                href={contact_url}
                                className={styles.mobileMenuLink}
                            >
                                Connect <Svg name="arrow-right" />
                            </Clickable>
                            <footer>
                                <ul className={styles.mobileMenuFooterList}>
                                    <li>
                                        <ButtonTertiary
                                            href="https://www.linkedin.com/company/thelab"
                                            target="_blank"
                                            noConditionalIcon={true}
                                        >
                                            Linkedin
                                        </ButtonTertiary>
                                    </li>
                                    <li className={styles.privacy}>
                                        <ButtonTertiary href="/privacy-policy">
                                            Privacy
                                        </ButtonTertiary>
                                    </li>
                                    <li>
                                        <ButtonTertiary href="/modern-slavery-statement">
                                            Modern Slavery Statement
                                        </ButtonTertiary>
                                    </li>
                                    <li>
                                        <ButtonTertiary href="/whistleblower-policy">
                                            Whistleblower Protection
                                        </ButtonTertiary>
                                    </li>
                                </ul>
                                <p>
                                    ©2024 thelab. Part of{" "}
                                    <Clickable
                                        href="https://www.wellcomww.com/"
                                        target="_blank"
                                    >
                                        <Svg name="wellcom" />
                                        <VisuallyHidden elementType="span">
                                            Wellcom
                                        </VisuallyHidden>
                                    </Clickable>{" "}
                                    worldwide
                                </p>
                            </footer>
                        </Dialog>
                    </Modal>
                </DialogTrigger>
            </div>
            <ScrollProgressBar />
        </nav>
    );
};
