import { useCurrentBreakpoint } from "@client/utils";
import { useGSAP } from "@gsap/react";
import * as Sentry from "@sentry/browser";
import { gsap } from "gsap/dist/gsap";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { VisuallyHidden } from "react-aria";
import {
    Dialog,
    DialogTrigger,
    Modal,
    ModalOverlay,
} from "react-aria-components";
import { createPortal } from "react-dom";

import { ExpandMediaBlock } from "@reactivated";

import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

const CustomCursor = forwardRef<
    HTMLDivElement,
    { isMoving: boolean; children: React.ReactNode }
>(({ isMoving, children }, ref) => {
    if (typeof document === "undefined") return;

    const cursor = (
        <div
            ref={ref}
            className={concatClassNames([
                styles.customCursor,
                isMoving ? styles.moving : "",
            ])}
            aria-hidden="true"
        >
            {children}
        </div>
    );

    return isMoving ? createPortal(cursor, document.body) : cursor;
});

export default function ExpandMedia(props: ExpandMediaBlock) {
    const containerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    const cursorRef = useRef<HTMLDivElement>(null);
    const closeRef = useRef<HTMLButtonElement>(null);
    const xyRef = useRef<[number | null, number | null]>([null, null]);

    const [isOpen, setIsOpen] = useState(false);
    const [cursorMoving, setCursorMoving] = useState(false);
    const [cursorText, setCursorText] = useState("Play");
    const [showMobileVideo, setShowMobileVideo] = useState(false);
    /**
     * Can't use `viewport.belowMobile` directly because going in and out of
     * fullscreen can trigger a viewport change that we don't actually want
     */
    const [belowMobile, setBelowMobile] = useState(false);

    const viewport = useCurrentBreakpoint();

    useGSAP(() => {
        gsap.to(containerRef.current, {
            scrollTrigger: {
                trigger: containerRef.current,
                start: "top 70%",
                end: () => (viewport.aboveTablet ? "top top" : "top 50%"),
                scrub: true,
            },
            padding: 0,
        });
    });

    const getPosterSrc = () => {
        let posterSrc = props.media?.poster;
        if (!props.poster_media || props.poster_media.length === 0)
            return posterSrc || "";
        if (props.poster_media[0].type === "image") {
            posterSrc = props.poster_media[0].value.url;
        }
        if (props.poster_media[0].type === "video") {
            posterSrc = props.poster_media[0].value.poster;
        }
        return posterSrc || "";
    };

    const dialogOpenChange = (isOpen: boolean) => setIsOpen(isOpen);

    useEffect(() => {
        if (!videoRef.current) return;
        if (isOpen) {
            setCursorMoving(false);
        } else {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setCursorText("Play");
        }
    }, [isOpen]);

    useEffect(() => {
        if (showMobileVideo === false) return;
        if (videoRef.current) {
            try {
                videoRef.current
                    .requestFullscreen({ navigationUI: "hide" })
                    .then(() => {
                        setCursorMoving(false);
                    })
                    .catch((error) => Sentry.captureException(error));
            } catch (error) {
                videoRef.current.addEventListener(
                    "webkitbeginfullscreen",
                    () => {
                        setCursorMoving(false);
                    },
                );
                videoRef.current.addEventListener("webkitendfullscreen", () => {
                    setShowMobileVideo(false);
                });
            }
        }
    }, [showMobileVideo]);

    useEffect(() => {
        const [xPos, yPos] = xyRef.current;
        if (xPos === null || yPos === null) return;
        if (!cursorRef.current) return;
        cursorRef.current.style.top = `${yPos}px`;
        cursorRef.current.style.left = `${xPos}px`;
    }, [cursorMoving]);

    useEffect(() => {
        setBelowMobile(viewport.belowMobile);

        const handleMouseMove = (event: MouseEvent) => {
            xyRef.current = [event.clientX, event.clientY];
            const [xPos, yPos] = xyRef.current;
            if (xPos === null || yPos === null) return;
            if (!cursorRef.current || !containerRef.current) return;
            if (!event.target || !(event.target instanceof HTMLElement)) return;

            setCursorMoving(
                containerRef.current.matches(":hover") ||
                    (modalRef.current && modalRef.current.matches(":hover")) ||
                    false,
            );

            cursorRef.current.style.top = `${yPos}px`;
            cursorRef.current.style.left = `${xPos}px`;
            const elementFromPoint = document.elementFromPoint(xPos, yPos);
            if (elementFromPoint === videoRef.current) {
                if (!(videoRef.current instanceof HTMLVideoElement)) return;
                const videoBottom =
                    videoRef.current.getBoundingClientRect().bottom;
                setCursorMoving(videoBottom - yPos > 100);
                setCursorText(videoRef.current.paused ? "Play" : "Pause");
            } else if (
                elementFromPoint === closeRef.current ||
                elementFromPoint?.parentElement === closeRef.current
            ) {
                setCursorMoving(false);
            } else if (elementFromPoint === modalRef.current) {
                setCursorText("Close");
            } else {
                setCursorText("Play");
            }
        };

        /**
         * Hide custom cursor if we scroll outside of the container. Ideally
         * this would also make it visible if you scroll into it but I don't
         * think it's possible to get cursor coordinates without a mouse event
         */
        const handleScroll = () => {
            setCursorMoving(containerRef.current?.matches(":hover") || false);
        };

        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setShowMobileVideo(false);
            }
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("scroll", handleScroll);
        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("scroll", handleScroll);
            document.removeEventListener(
                "fullscreenchange",
                handleFullscreenChange,
            );
        };
    }, []);

    useEffect(() => {
        if (!document.fullscreenElement) {
            setBelowMobile(viewport.belowMobile);
        }
    }, [viewport]);

    return (
        <DialogTrigger onOpenChange={dialogOpenChange}>
            {belowMobile ? (
                <div ref={containerRef} className={styles.noModal}>
                    <button
                        className={styles.videoButton}
                        onClick={() => {
                            setShowMobileVideo(true);
                        }}
                    >
                        <VisuallyHidden>Play Video</VisuallyHidden>
                        <CustomCursor isMoving={false} ref={cursorRef}>
                            {cursorText}
                        </CustomCursor>
                    </button>
                    {props.poster_media?.[0]?.type === "video" ? (
                        <VideoChooserBlock
                            value={props.poster_media[0].value}
                            iOSFriendlyMutedAutoPlay={true}
                            attrs={{
                                className: styles.mobileMediaPoster,
                                loop: true,
                                muted: true,
                            }}
                        />
                    ) : (
                        <img
                            src={getPosterSrc()}
                            className={concatClassNames([styles.mediaPoster])}
                        />
                    )}
                    {showMobileVideo && props.media && (
                        <VideoChooserBlock
                            value={props.media}
                            ref={videoRef}
                            attrs={{
                                className: styles.media,
                                poster: undefined,
                                controls: true,
                                autoPlay: true,
                                playsInline: false,
                            }}
                        />
                    )}
                </div>
            ) : (
                <div
                    ref={containerRef}
                    className={concatClassNames([
                        styles.block,
                        cursorMoving ? styles.showCustomCursor : "",
                    ])}
                >
                    <div className={styles.mediaContainer}>
                        <Clickable className={styles.videoButton}>
                            <VisuallyHidden>Play Video</VisuallyHidden>
                            <CustomCursor
                                isMoving={cursorMoving}
                                ref={cursorRef}
                            >
                                {cursorText}
                            </CustomCursor>
                        </Clickable>
                        {props.poster_media?.[0]?.type === "video" ? (
                            <VideoChooserBlock
                                value={props.poster_media[0].value}
                                iOSFriendlyMutedAutoPlay={true}
                                attrs={{
                                    className: styles.mediaPoster,
                                    loop: true,
                                    muted: true,
                                }}
                            />
                        ) : (
                            <img
                                src={getPosterSrc()}
                                className={styles.mediaPoster}
                            />
                        )}
                        <ModalOverlay
                            className={concatClassNames([
                                styles.overlay,
                                cursorMoving ? styles.showCustomCursor : "",
                            ])}
                            ref={modalRef}
                            isDismissable
                        >
                            <Modal className={styles.modal}>
                                <Dialog
                                    className={styles.dialog}
                                    aria-label="Connect with us."
                                >
                                    {({ close }) =>
                                        props.media ? (
                                            <>
                                                <Clickable
                                                    className={styles.close}
                                                    onClick={close}
                                                    ref={closeRef}
                                                >
                                                    <Svg name="close" />
                                                </Clickable>
                                                <VideoChooserBlock
                                                    ref={videoRef}
                                                    value={props.media}
                                                    attrs={{
                                                        className: styles.media,
                                                        poster: undefined,
                                                        controls: true,
                                                        autoPlay: true,
                                                        playsInline: false,
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Dialog>
                            </Modal>
                        </ModalOverlay>
                    </div>
                </div>
            )}
        </DialogTrigger>
    );
}
