import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import { Provider, getServerData, getTemplate } from "@reactivated";

const { props, context } = getServerData();

if (context.SENTRY_DSN) {
    Sentry.init({
        dsn: context.SENTRY_DSN,
        release: context.CI_COMMIT_SHA,
        environment: context.CI_COMMIT_REF_SLUG,
        integrations: [],
    });
}

const main = async () => {
    const Template = await getTemplate(context);

    const root = createRoot(document.getElementById("root")!);
    root.render(
        <HelmetProvider>
            <Provider value={context}>
                <Template {...props} />
            </Provider>
        </HelmetProvider>,
    );
};

await main();
