import React from "react";

import {
    MediaChooserBlock as MediaChooserBlockInterface,
    SplitBlock as Value,
} from "@reactivated";

import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { RichTextBlock } from "../RichText";
import { VideoWithPlayPauseButton } from "../VideoWithPlayPauseButton";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const SplitBlock = ({ value }: Props) => {
    const renderSplitBlockMedia = (media: MediaChooserBlockInterface) =>
        media[0].type === "video" ? (
            <VideoWithPlayPauseButton
                value={media[0].value}
                wrapperAttrs={{ className: styles.mediaWrapper }}
                attrs={{
                    onLoad: () => {
                        ScrollTrigger.refresh();
                    },
                }}
            />
        ) : (
            <div className={styles.mediaWrapper}>
                <MediaChooserBlock value={media} />
            </div>
        );

    return (
        <div
            className={concatClassNames([
                styles.splitBlock,
                styles[`splitAlignment${value.alignment!}`],
            ])}
        >
            <RichTextBlock
                className={styles.splitBlockText}
                largeHeadings={true}
                value={value.text!}
            />
            {value.media && renderSplitBlockMedia(value.media)}
        </div>
    );
};
