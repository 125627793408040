import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";
import { RichTextBlock } from "../components/RichText";

import styles from "../styles/legal.module.scss";

export const Template = (props: templates.LegalPageTemplate) => {
    return (
        <React.StrictMode>
            <PageSkeleton {...props.page}>
                <section className={styles.container}>
                    <RichTextBlock
                        className={styles.legalRichText}
                        value={props.page.body.html}
                    />
                </section>
            </PageSkeleton>
        </React.StrictMode>
    );
};
