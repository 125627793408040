import React from "react";
import {
    TextField as BaseTextField,
    Input,
    Label,
    Text,
    TextArea,
    TextFieldProps,
} from "react-aria-components";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { CommonFieldProps, FieldError } from "./utils";

import styles from "./inputs.module.scss";

export function TextField({
    label,
    description,
    errorMessage,
    className,
    multiline = false,
    onChange,
    ...props
}: CommonFieldProps & TextFieldProps & { multiline?: boolean }) {
    const [isEmpty, setIsEmpty] = React.useState(
        (props.defaultValue ?? props.value ?? "").length == 0,
    );
    return (
        <BaseTextField
            {...props}
            onChange={(e) => {
                if (onChange) onChange(e);
                setIsEmpty(e.length === 0);
            }}
            className={concatClassNames([
                typeof className === "string" ? className : undefined, //React-Aria does some fancy function stuff I'm ignoring for now
                styles.textField,
            ])}
        >
            <div className={styles.fieldWrapper}>
                <Label>{label}</Label>
                {multiline ? (
                    <TextArea
                        className={concatClassNames([
                            "react-aria-TextArea",
                            isEmpty ? styles.empty : undefined,
                        ])}
                    />
                ) : (
                    <Input />
                )}
            </div>
            {description && <Text slot="description">{description}</Text>}
            <FieldError>{errorMessage}</FieldError>
        </BaseTextField>
    );
}
