import React from "react";
import {
    FieldError as BaseFieldError,
    FieldErrorProps,
} from "react-aria-components";

export function FieldError({ children, ...props }: FieldErrorProps) {
    if (children) return <FieldError {...props}>{children}</FieldError>;
    return (
        <BaseFieldError {...props}>
            {({ validationDetails, validationErrors }) => {
                if (validationDetails.valueMissing)
                    return "This field is required";
                return validationErrors;
            }}
        </BaseFieldError>
    );
}

export interface CommonFieldProps {
    errorMessage?: string;
    label: string;
    description?: string;
}
