import themes from "@client/styles/theming.module.css";
import React from "react";

import { QuotesBlock as QuotesBlockProps } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import Carousel, { CarouselNavigationButtons } from "../Carousel";

import styles from "./index.module.scss";

export default function QuotesBlock(value: QuotesBlockProps) {
    const bgColor = `#${value.background_color ?? "ffffff"}`;

    return (
        <section style={{ backgroundColor: bgColor }}>
            <Carousel
                label="Quotes from our client"
                controls={
                    <CarouselNavigationButtons
                        className={styles.quoteNav}
                        style="filled"
                    />
                }
                className={concatClassNames([
                    styles.quoteBlock,
                    value.text_color === "dark" ? themes.light : themes.dark,
                ])}
            >
                {value.quotes?.map((block) => {
                    return (
                        <React.Fragment key={block.id}>
                            <p className={styles.quote}>“{block.value.body}”</p>
                            <p className={styles.attribution}>
                                {block.value.attribution}
                            </p>
                        </React.Fragment>
                    );
                })}
            </Carousel>
        </section>
    );
}
