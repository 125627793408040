import React from "react";
import { OverlayTriggerStateContext } from "react-aria-components";

import { Context, constants } from "@reactivated";

export const DialogHooks = () => {
    const { contact_form_page } = React.useContext(Context);
    const dialogState = React.useContext(OverlayTriggerStateContext);

    // Hijack links to the contact us page so that they just open the modal form
    // instead.
    React.useEffect(() => {
        // url looks like `/contact-us/`
        const url = contact_form_page?.url;
        const hijackContactLinks = (e: MouseEvent) => {
            if (
                url &&
                e.target instanceof HTMLAnchorElement &&
                e.target.href ==
                    new URL(url, window.location.toString()).toString()
            ) {
                e.preventDefault();
                dialogState.setOpen(true);
            }
        };
        document.body.addEventListener("click", hijackContactLinks);
        return () => {
            document.body.removeEventListener("click", hijackContactLinks);
        };
    }, [contact_form_page?.url]);

    // If the URL params include `?open-contact-form=yes`, auto open the form.
    React.useEffect(() => {
        const url = new URL(window.location.href);
        const val = url.searchParams.get(
            constants.AUTO_OPEN_CONTACT_FORM_PARAM,
        );
        if (val !== constants.AUTO_OPEN_CONTACT_FORM_VALUE) {
            return;
        }
        // Open the form
        dialogState.setOpen(true);
        // Remove the param from the URL
        url.searchParams.delete(constants.AUTO_OPEN_CONTACT_FORM_PARAM);
        window.history.replaceState(null, "", url.toString());
    }, []);

    return null;
};
