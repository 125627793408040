import themes from "@client/styles/theming.module.css";
import { useIsClient } from "@client/utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";

import { ServicesLocationsBlock } from "@reactivated";

import { RichTextBlock } from "../RichText";

import styles from "./services.module.scss";

dayjs.extend(utc);

// The first render on the client and server must match, so we hard code the time until hydration
function useCurrentTime() {
    const isClient = useIsClient();
    if (isClient) return dayjs();
    else return dayjs("9:00PM");
}

export default function LocationsBlock(props: ServicesLocationsBlock) {
    const now = useCurrentTime();
    return (
        <section
            className={
                props.color_theme ? themes[props.color_theme] : undefined
            }
        >
            <div className={styles.locationsBlock}>
                {props.body_left && (
                    <RichTextBlock
                        className={styles.half}
                        value={props.body_left}
                        largeHeadings={true}
                    />
                )}
                {props.body_right && (
                    <RichTextBlock
                        className={styles.half}
                        value={props.body_right}
                    />
                )}
                {props.locations?.map(({ value: loc, id }) => (
                    <article className={styles.location} key={id}>
                        <h3>{loc.title}</h3>
                        <time>
                            <span>
                                {now
                                    .utcOffset(loc.timezone ?? -4)
                                    .format("h:mmA")}
                            </span>
                            <span>UTC/GMT{loc.timezone}</span>
                        </time>
                        {loc.hiring && <p>We are hiring</p>}
                    </article>
                ))}
            </div>
        </section>
    );
}
