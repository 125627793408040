import React from "react";

import { LPHeroBlock as LPHeroBlockValue } from "@reactivated";

import { RichTextBlock } from "../RichText";

import styles from "./index.module.scss";

export default function LPHeroBlock(props: LPHeroBlockValue) {
    return (
        <div className={styles.container}>
            <RichTextBlock
                className={styles.heading}
                value={props.heading ?? ""}
            />
            <RichTextBlock className={styles.copy} value={props.copy ?? ""} />
        </div>
    );
}
