import React from "react";

import { templates } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import CaseStudyGridBlock from "../components/CaseStudyGrid";
import FeaturedCaseStudies from "../components/FeaturedCaseStudies";
import LPHeroBlock from "../components/LPHero";
import { PageSkeleton } from "../components/PageSkeleton";
import { SpacerBlock } from "../components/SpacerBlock";

export const Template = (props: templates.WorkPageTemplate) => {
    const logoFadeInRef = React.useRef<HTMLDivElement>(null);

    return (
        <React.StrictMode>
            <PageSkeleton {...props.page} fixedLogoFadeInRef={logoFadeInRef}>
                <StreamField
                    tagName={React.Fragment}
                    value={props.page.body.value}
                    components={{
                        hero: (block) => (
                            <>
                                <div ref={logoFadeInRef} />
                                <LPHeroBlock {...block.value} />
                            </>
                        ),
                        featured_case_studies: (block) => (
                            <FeaturedCaseStudies {...block.value} />
                        ),
                        case_study_grid: (block) => (
                            <CaseStudyGridBlock {...block} />
                        ),
                        spacer: (block) => <SpacerBlock value={block.value} />,
                    }}
                />
            </PageSkeleton>
        </React.StrictMode>
    );
};
