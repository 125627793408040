import React from "react";

import { Context, Provider, templates } from "@reactivated";

import { ButtonPrimary } from "../components/Clickables";
import ContactUsForm from "../components/ContactUsForm";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = function ContactUsPage(
    props: templates.ContactUsPageTemplate,
) {
    if (!props.is_preview) {
        throw new Error(
            "This page type should never render outside of preview",
        );
    }
    const context = React.useContext(Context);
    return (
        <>
            <Provider
                value={{
                    ...context,
                    contact_form: props.form,
                    contact_form_page: props.page,
                }}
            >
                <ContactUsForm defaultIsOpen={true}>
                    <ButtonPrimary>Hello preview</ButtonPrimary>
                </ContactUsForm>
            </Provider>
            <PageSkeleton {...props.page}>
                <div style={{ minHeight: "100vh" }} />
            </PageSkeleton>
        </>
    );
};
